<template>
  <div>
    <div class="important-news">
      <div class="text-container">
        <h3 class="text-center mb-3">
          Aktuell: Erhöhung des Krankenkassenrabattes
        </h3>
        <p>
          Durch die Erhöhung des Krankenkassenrabattes von 1,77 € auf 2,00 €
          verliert die Durchschnittsapotheke 12.000,00 € im Betriebsergebnis.
        </p>
        <p>
          Hinzu kommen Erhöhungen der Personalkosten, der Energiekosten und der
          Zinsen, so dass die Musterapotheke insgesamt 42.000,00 € Mehrkosten
          tragen muss.
        </p>
        <p>
          Mit der richtigen Einkaufspolitik und einer geschätzten Umsatzerhöhung
          um 5 % können Sie insgesamt ca. 25.000,00 € auffangen. Wichtig ist es
          den Einkauf zu optimieren, das Personal richtig einzusetzen und über
          die
          <a href="/leistungen/automaten"
            >Anschaffung eines Kommissionier Automaten</a
          >
          nachzudenken.
        </p>
        <p>
          Wir bieten Ihnen mit unserem Unternehmen Hilfestellung als
          Problemlöser an.
        </p>
        <div class="text-center mt-4">
          <b-button
            variant="primary"
            class="button px-xl-4 px-sm-1"
            href="#contact"
          >
            Jetzt Kontakt aufnehmen
          </b-button>
        </div>
      </div>
    </div>
    <div class="text-container">
      <p>
        "Der Segen liegt im Einkauf". Häufig haben wir diesen Spruch schon
        gehört. Doch wird auch dementsprechend gehandelt?
      </p>
      <ul>
        <li>
          Wir überprüfen für Sie den Gesamteinkauf der Apotheke. Stimmt das
          Verhältnis Direkteinkauf gegenüber dem Großhandel?
        </li>
        <li>
          Verbesserung des Rohertrages durch Überprüfung der Direktkonditionen
        </li>
        <li>
          Wir verfügen über persönliche und jahrelange Erfahrungen bei
          Verhandlungen mit den Industriepartnern. Über 10 Jahre haben wir einen
          eigenen Großhandel betrieben, der der Hauptapotheke angeschlossen war.
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.important-news {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f2d3ce;
}
</style>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir überprüfen für Sie den Gesamteinkauf der Apotheke. Stimmt das Verhältnis Direkteinkauf gegenüber dem Großhandel?",
      },
      {
        property: "og:image",
        content:
          "https://awh-beratung.de/assets/visuals/beratung-wirtschaft.png",
      },
    ],
  },
};
</script>